.container {
  flex: 1;
  width: 100%;
  padding: 0;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
}
.col1 {
  flex-direction: column;
  width: 20%;
  flex-wrap: wrap;
  max-width: 100%;
  flex-grow: 1;
  margin: 0;
  padding: 0;
}
.col2 {
  flex-direction: column;
  width: 50%;
  max-width: 100%;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  align-items: flex-start;
}

.col3 {
  flex-direction: column;
  width: 30%;
  flex-wrap: wrap;
  max-width: 100%;
  flex-grow: 1;
  margin: 0;
  padding: 0;
}
.row {
  display: flex;
  flex-direction: row;
  border-color: red;
  border-width: thin;

  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 100%;
  flex-grow: 1;
  margin: 0;
  padding: 0;
}
.card {
  margin: 5px;
  max-width: 100%;
  min-width: 40px;
  font-family: "Roboto, sans-serif";
}
.card2 {
  margin: 5px;
  max-width: 100%;
  min-width: 40px;
  flex-grow: 1;
  width: 30px;
  align-items: center;
  text-align: center;
  border-bottom: solid;
  border-color: navy;
  border-width: thin;
}

.rowAvatars {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.avatar {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.span {
  margin-top: 5px;
}
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
}

.graphBig {
  max-height: 200px;
}
.appBar {
  text-align: center;
}

.test {
  text-align: center;
  flex: 1;
  display: "flex";
  padding-top: 10;
  padding-bottom: 10;
  flex-direction: "row";
  align-content: "flex-end";
}

.ra-rich-text-input .ql-editor {
  font-size: 22px !important;
}
.RaRichTextInput {
  font-size: "2em" !important;
}

.ql-editor {
  font-size: "2em" !important;
}
